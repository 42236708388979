import { Fragment, Suspense, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ALL_LINKS } from "./constant/routes";
import AuthContext from "./store/AuthContext";
import './index.css';

const App = () => {
  const authCtx = useContext(AuthContext);
  const PAGES = [
    ALL_LINKS.LOGIN,
    ALL_LINKS.FORGOT_PASSWORD,
    ALL_LINKS.DASHBOARD,
    ALL_LINKS.USER_DETAIL,
    ALL_LINKS.ORGANIZATION,
    ALL_LINKS.USERS,
  ]

  return (
    <Fragment>
      <Router>
        <Suspense fallback={'Loading...'}>
          <Routes>
            {PAGES.map((item, i) => {
              return ((item.loginRequired) ? (
                <Route key={i} exact path={item.pageLink} element={authCtx.isLoggedIn ? <item.view /> : <Navigate to={ALL_LINKS.LOGIN.pageLink} />} />
              ) :
                <Route key={i} exact path={item.pageLink} element={<item.view />} />)
            })}
          </Routes>
        </Suspense>
      </Router>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </Fragment>
  );
}

export default App;