import axios from 'axios';
import http from './http';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SUB_URL = 'auth/org/';

export const login = (data) => {
    return axios.post(`${BASE_URL}${SUB_URL}login`, data);
}

export const logout = (data) => {
    return http.post(`${SUB_URL}logout`, data);
}

export const forgotPassword = (data) => {
    return axios.post(`${BASE_URL}${SUB_URL}forgot`, data);
}

export const getUsers = () => {
    return http.get(`users?org_id=1&page=1&limit=10&order=asc&user_name=daniel`);
}